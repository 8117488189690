<template>
  <div class="career">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">{{ careerPage.name }}</div>
      <p class="text">{{ careerPage.description }}</p>
      <div class="buttongroup">
        <a :href="`${API_URL}${careerPage.form.url}`" download class="active">
          <img src="@/assets/img/download_white.svg" /> Скачать анкету
        </a>
        <button class="link">
          <label>
            <input id="document-input" type="file" @input="onUpload" />
            <img src="@/assets/img/clip.svg" /> {{ buttonText }}
          </label>
        </button>
        <button class="send" :class="fileUploaded ? 'uploaded' : ''" @click="uploadFile">
          <img :src="fileUploaded ? attachFileWhite : attachFile" /> Отправить
        </button>
      </div>
      <div class="vacancy-list">
        <VacancyItem :vacancy="vacancy" v-for="(vacancy, i) in vacanciesList" :key="i" />
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/career.sass';
import config from '@/config';
import { mapState } from 'vuex';
import VacancyItem from '@/components/Vacancy/VacancyItem.vue';
import Loader from '@/components/Loader.vue';
import attachFile from '@/assets/img/attach_file.svg';
import attachFileWhite from '@/assets/img/attach_file-white.svg';

export default {
  name: 'Career',
  components: { Loader, VacancyItem },
  data() {
    return {
      buttonText: 'Прикрепить резюме',
      file: null,
      isLoading: true,
      fileUploaded: false,
      attachFileWhite,
      attachFile,
    };
  },
  computed: {
    ...mapState({
      careerPage: (state) => state.career.careerPage,
      vacanciesList: (state) => state.career.vacanciesList,
    }),
    API_URL() {
      return config.API_URL;
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('getVacanciesList').then(() => {
        this.isLoading = false;
      });
    },
    loadPageData() {
      this.$store.dispatch('getCareerPage').then(this.loadData);
    },
    onUpload(event) {
      this.fileUploaded = true;
      const file = event.target.files[0];
      this.file = file;
      this.buttonText = file.name;
    },
    uploadFile() {
      this.$store.dispatch('sendSummary', this.file).then(() => {
        this.fileUploaded = false;
        this.file = null;
        this.buttonText = 'Прикрепить резюме';
        document.getElementById('document-input').value = '';
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadPageData();
    this.$emit('setLastCrumb', 'Вакансии');
  },
};
</script>
