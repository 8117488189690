<template>
  <div class="vacancy-item">
    <div class="vacancy-item__top">
      <span class="content content--big">{{ vacancy.name }}</span>
      <span class="content">
        {{ vacancy.created_at | moment("DD.MM.YYYY") }}
      </span>
    </div>
    <div class="vacancy-item__description" v-if="isOpen">
      <div
        class="marked-text marked-text--line"
        :class="{ open: isOpen }"
        v-html="marked(vacancy.responsibilities)"
      ></div>
    </div>
    <div class="vacancy-item__bottom">
      <span class="content">{{ isOpen ? vacancy.salary : "" }}</span>
      <button @click="isOpen = !isOpen" class="details">
        <span>{{ isOpen ? "Свернуть" : "Подробнее" }}</span>
        <img class="icon" :src="isOpen ? blueTriangle : invertedBlueTriangle" width="10" height="8" />
      </button>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/vacancy-item.sass';
import blueTriangle from '@/assets/img/blue_triangle.svg';
import invertedBlueTriangle from '@/assets/img/inverted_blue_triangle.svg';
import marked from 'marked';

export default {
  name: 'VacancyItem',
  props: {
    vacancy: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      marked,
      isOpen: false,
      blueTriangle,
      invertedBlueTriangle,
    };
  },
};
</script>
